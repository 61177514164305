<template>
  <div class="login_bgr_color">
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
      </div>
      <!-- 登入表单区域 -->
      <el-form
        ref="loginFromRef"
        :model="loginFrom"
        :rules="loginFromRules"
        label-width="0px"
        class="login_from"
      >
        <!-- 用户名 -->
        <el-form-item prop="name">
          <el-input
            v-model="loginFrom.name"
            prefix-icon="iconfont icon-user"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            v-model="loginFrom.password"
            prefix-icon="iconfont icon-3702mima"
            placeholder="密码"
            type="password"
          ></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <!-- 提交 -->
          <el-button @click="login" type="primary">登入</el-button>
          <!-- 重置 -->
          <el-button @click="resetLoginFrom" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 这是登入表单的数据绑定对象
      loginFrom: {
        name: '',
        password: ''
      },
      // 这是表单的验证规则对象
      loginFromRules: {
        //验证用户名是否合规
        name: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '名称长度在 3 到 10 个字符',
            trigger: 'blur',
          },
        ],
        //验证密码是否合规
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 4,
            max: 15,
            message: '密码长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
      },

    }
  },
  methods: {
    // 点击重置按钮重置表单
    resetLoginFrom() {
      this.$refs.loginFromRef.resetFields()
    },
    login() {
      this.$refs.loginFromRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('user/login', this.loginFrom)
        if (res.code !== 200)
          return this.$message.error('登入失败，用户名或密码错误！')
        this.$message.success('恭喜您，登入成功！')
        // 1．将登录成功之后的 token，保存到客户端的sessionstorage中
        // 1.1项目中出了登录之外的其他API接口，必须在登录之后才能访问
        // 1.2 token只应在当前网站打开期间生效，所以将 token保存在 sessionStorage中
        window.sessionStorage.setItem('saToken', res.data)
        // 2．通过编程式导航跳转到后台主页，路由地址是 /home
        this.$router.push('/home')
      })



    },
  },
}
</script>

<style lang="less" scoped>
.login_bgr_color {
  background-color: #9bc852;
  height: 100%;
}
.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}
.login_from {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: center;
  button {
    margin: 0 20px;
  }
}
</style>
