<template>
  <div>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 面包屑导航区 -->
      <div class="shenhe">
        <my-component :crumbsLisTtitle="crumbsList.title"></my-component>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="审核筛选">
            <el-select placeholder="未审核" v-model="val">
              <el-option label="未审核" value="0"></el-option>
              <el-option label="已审核" value="1"></el-option>
              <el-option label="审核未通过" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="rightsList" border stripe>
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="申请人" prop="id"></el-table-column>
        <el-table-column label="申请类型" prop="w">{{
          chongzhi
        }}</el-table-column>
        <el-table-column label="金额" prop="money"></el-table-column>
        <el-table-column label="创建人" prop="createBy"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="状态" prop="stateString"></el-table-column>
        <el-table-column label="操作" prop="controls">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="
                showDialogVisible(
                  scope.row.id,
                  scope.row.userId,
                  scope.row.money,
                  scope.row.img
                )
              "
              v-if="scope.row.state === '0'"
              >审批</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 图片显示区 -->
    <el-dialog
      title="审核图片"
      :visible.sync="photoDialogVisible"
      width="35%"
      @close="photoDialogClosed"
      ref="photoFormRef"
    >
      <!-- 内容主体区 -->

      <!-- 底部区域 -->
      <div class="photo" style="width: 300rpx;height: 300rpx;">
        <img :src="'data:image/png;base64,' + img" alt="审核图片" style="max-width: 100%;
        max-height: 100%;
        display: block;
        margin: auto; " />
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateQuest(2)" type="danger">拒 绝</el-button>
        <el-button @click="updateQuest(1)" type="primary">同 意</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MyComponent from '../MyComponent.vue'
export default {
  components: { MyComponent },
  data() {
    return {
      // 权限列表
      rightsList: [],
      // 面包屑列表
      crumbsList: {
        title: '充值审批',
      },
      photoDialogVisible: false,
      val: '',
      // 充值审核列表
      topList: {
        id: '',
        userId: '',
        state: '',
        money: '',
      },
      chongzhi: '充值',
      img: ''
    }
  },
  created() {
    this.getRightsList()
  },
  methods: {
    // 获取权限列表
    async getRightsList() {
      const { data: res } = await this.$http.post('order/list/0')
      if (res.code !== 200) {
        return this.$message.error('获取充值审核列表失败！')
      }
      this.rightsList = res.data
    },
    async showDialogVisible(id, userId, money,img) {

      this.photoDialogVisible = true;
      this.topList.id = id;
      this.topList.userId = userId;
      this.topList.money = money;
      const { data: res } = await this.$http.get(`/download?name=${img}`)
      this.img = res.msg
    },
    // 任务审批操作
    async updateQuest(state) {
      this.topList.state = state
      console.log(this.topList)
      const { data: res } = await this.$http.post(
        'order/updateState',
        this.topList
      )
      if (res.code !== 200) {
        // this.$message.error('审批失败！')
      }
      this.$message.success('审批成功！')
      this.photoDialogVisible = false
      this.getRightsList()
    },
  },
  watch: {
    async val() {
      const { data: res } = await this.$http.post(`order/list/${this.val}`)
      if (res.code !== 200) {
        this.message.error('筛选状态失败！')
      }
      this.rightsList = res.data
      this.message.success('获取状态成功！')
    },
  },
}
</script>

<style lang="less" scoped>
  .photo {
     width: 200rpx;
     height: 200rpx;
  }
.shenhe {
  display: flex;
  justify-content: space-between;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
