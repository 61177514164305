import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
// 导入字体图标库
import './assets/fonts/iconfont.css'
// 导入全局组件
import MyComponent from './components/MyComponent.vue'

import Cookies from 'js-cookie'

// 使用全局组件
Vue.component('my-component', MyComponent)

import axios from "axios"


// const url = "http://127.0.0.1:9810"
// const url = "http://110.42.238.94:9810"
const url = "http://47.92.204.24:9810"
// 配置请求的根路径
axios.defaults.baseURL = url

// axios.defaults.baseURL = "http://127.0.0.1:9150"

// 将token与菜单响应头绑定
axios.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('saToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      // 请求头携带token
      config.headers.satoken = token
      Cookies.set('Cookie', `satoken=${token}`)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 设置axios为form-data
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.transformRequest = [function (data) {
//   let ret = ''
//   for (let it in data) {
//     ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//   }
//   return ret
// }]

axios.interceptors.request.use(function (config) {
  if(config.method === 'post') { // 判断如果是put请求, 配置content-type
      config.headers['Content-Type'] = 'application/json'
  }else {
      config.headers['Content-Type'] = 'application/json'
  }
  return config
}, function(error) {
  return Promise.reject(error)
})

// axios.defaults.withCredentials = true;

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
