<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header class="headerStyle">
      <div>
        <span>【天意双胆王 福彩3d大小 单双 预测软件】激活码管理平台</span>
      </div>
      <el-button class="buttonColor" @click="logout">退出</el-button>
    </el-header>
    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'" class="conStyle">
        <div class="toggle-bytton" @click="toggleCollapse">III</div>
        <!-- 侧边栏菜单区 -->
        <el-menu
          background-color="#ffffff"
          text-color="#46562c"
          active-text-color="#B8926A"
          unique-opened
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
          <!-- 一级菜单 -->
          <el-submenu :index="1">
            <!-- 一级菜单模板区 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iconsObj[102]"></i>
              <!-- 文本 -->
              <span>激活码管理</span>
            </template>

            <!-- 二级菜单 -->
            <router-link to="/quest" style="text-decoration: none;">
              <el-menu-item :index="1 - 1">
                <template slot="title">
                  <!-- 图标 -->
                  <i class="el-icon-menu"></i>
                  <!-- 文本 -->
                  <span>激活码列表</span>
                </template>
              </el-menu-item>
            </router-link>
          </el-submenu>



          <el-submenu :index="2" v-if="false">
            <!-- 一级菜单模板区 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iconsObj[125]"></i>
              <!-- 文本 -->
              <span>用户管理</span>
            </template>

            <!-- 二级菜单 -->
            <router-link to="/user" style="text-decoration: none;">
              <el-menu-item :index="3 - 1">
                <template slot="title">
                  <!-- 图标 -->
                  <i class="el-icon-menu"></i>
                  <!-- 文本 -->
                  <span>用户操作</span>
                </template>
              </el-menu-item>
            </router-link>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menulist: [],
      iconsObj: {
        125: 'iconfont icon-user',
        103: 'iconfont icon-tijikongjian',
        101: 'iconfont icon-shangpin',
        102: 'iconfont icon-danju',
        145: 'iconfont icon-baobiao',
      },
      // 是否折叠
      isCollapse: false,
    }
  },
  methods: {
    // 退出按钮方法
    logout() {
      // 清空token
      window.sessionStorage.clear()
      // 跳转到重新登入
      this.$router.push('/login')
    },
    // 点击按钮切换菜单折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
  },
}
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}

.el-header {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  align-items: center;
  color: red;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
    img {
      width: 55px;
      border-radius: 50%;
      background-color: #ffffff;
    }
  }
}

.el-aside {
  background-color: #ffffff;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #e6e6e6;
}

.iconfont {
  margin-right: 10px;
  color: #e6e6e6;
}

.toggle-bytton {
  background-color: #e6e6e6;
  font-size: 15px;
  line-height: 24px;
  color: white;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.buttonColor {
  background-color: #b5c499;
}
.headerStyle {
  border-bottom: 2px solid rgb(151, 146, 146);
  border-top: 2px solid rgb(151, 146, 146);
}
.conStyle {
  border-right: 2px solid rgb(151, 146, 146);
}
</style>
