<template>
  <div>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 面包屑导航区 -->
      <div class="shenhe">
        <my-component :crumbsLisTtitle="crumbsList.title"></my-component>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="激活码筛选">
            <el-select placeholder="激活码状态" v-model="val">
              <el-option label="未激活" value="0"></el-option>
              <el-option label="使用中" value="1"></el-option>
              <el-option label="已过期" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="rightsList" border stripe>
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="申请人" prop="userId"></el-table-column>
        <el-table-column label="申请类型" prop="file"></el-table-column>
        <el-table-column label="金额" prop="price"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="状态" prop="stateString"></el-table-column>
        <el-table-column label="操作" prop="controls">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="showDialogVisible(scope.row.id,scope.row.taskId, scope.row.file ,scope.row.userId)"
              v-show="scope.row.state === '0'"
              >审批</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import MyComponent from '../MyComponent.vue'
export default {
  components: { MyComponent },
  data() {
    return {
      // 权限列表
      rightsList: [],
      // 面包屑列表
      crumbsList: {
        title: '任务审批',
      },
      photoDialogVisible: false,
      val: '',
      topList: {
        id: '',
        taskId: '',
        state: '',
        userId:''
      },
      img: '',
    }
  },
  created() {
    this.getRightsList()
  },
  methods: {
    // 获取任务审批
    async getRightsList() {
      const { data: res } = await this.$http.post('task/taskLog/0')
      if (res.code !== 200) {
        return this.$message.error('获取权限列表失败！')
      }
      this.rightsList = res.data
    },
    async showDialogVisible(id, taskId, file , userId) {
      this.photoDialogVisible = true
      this.topList.id = id
      this.topList.taskId = taskId
      this.topList.userId = userId
      const { data: res } = await this.$http.get(`/download?name=${file}`)
      this.img = res.msg
    },
    // 任务审批操作
    async updateQuest(state) {
      this.topList.state = state
      const { data: res } = await this.$http.post(
        'task/updateState',
        this.topList
      )
      if (res.code !== 200) {
        this.$message.error('审批失败！')
      }
      this.$message.success('审批成功！')
      this.photoDialogVisible = false
      this.getRightsList()
    },
  },
  watch: {
    async val() {
      const { data: res } = await this.$http.post(`task/taskLog/${this.val}`)
      if (res.code !== 200) {
        this.message.error('筛选状态失败！')
      }
      this.rightsList = res.data
    },
  },
}
</script>

<style lang="less" scoped>
.shenhe {
  .photo {
    width: 500rpx !important;
    height: 500rpx !important;
  }
  display: flex;
  justify-content: space-between;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
