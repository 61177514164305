<template>
  <div>
    <!-- 卡片视图 -->
    <el-card class="box-card">
      <div class="cardList">
        <my-component :crumbsLisTtitle="crumbsList.title"></my-component>
        <!-- 搜索与添加区 -->
        <el-form :inline="true" class="demo-form-inline">
         <el-form-item label="激活码筛选">
           <el-select placeholder="激活码状态" v-model="val">
             <el-option label="未激活" value="0"></el-option>
             <el-option label="使用中" value="1"></el-option>
             <el-option label="已过期" value="2"></el-option>
           </el-select>
         </el-form-item>
        </el-form>

        <el-button
          type="primary"
          @click="updatePassword = true"
          class="seash"
        >
          <div class="addUserWrite">修改密码</div>
        </el-button>

<!-- 
        <el-button
          type="primary"
          @click="updatereL = true"
          class="seash"
        >
          <div class="addUserWrite">修改提示消息</div>
        </el-button> -->

        <el-button
          type="primary"
          @click="addDialogVisible = true"
          class="seash"
        >
          <div class="addUserWrite">生成激活码</div>
        </el-button>
      </div>
      <!-- 用户列表区 -->
      <el-table :data="questList" border stripe>
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="激活码" prop="activationCode"></el-table-column>
        <el-table-column label="激活状态" prop="state"></el-table-column>
        <el-table-column label="激活时间" prop="createTime"></el-table-column>
        <el-table-column label="激活码有效期" prop="outTime"></el-table-column>
        <!-- <el-table-column label="激活码到期时间" prop="outTimeText"></el-table-column> -->
      </el-table>
    </el-card>

    <!-- 添加用户区 -->
    <el-dialog
      title=""
      :visible.sync="addDialogVisible"
      width="35%"
      @close="addDialogClosed"
      ref="addFormRef"
    >
      <!-- 内容主体区 -->
      <el-form :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="有效期(小时)" prop="ask">
          <el-input v-model="addForm.outTime"></el-input>
        </el-form-item>
        <el-form-item label="生成数量" prop="number">
          <el-input v-model="addForm.number"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button @click="addUser" type="primary">确 定</el-button>
      </span>
    </el-dialog>


<el-dialog
      title=""
      :visible.sync="updatePassword"
      width="35%"
      @close="addDialogClosed"
      ref="addFormRef"
    >
      <!-- 内容主体区 -->
      <el-form :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="原密码" prop="password">
          <el-input v-model="updatePass.password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="updatePass.newPassword"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="updatePassword = false">取 消</el-button>
        <el-button @click="updatePassT()" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="注意: 提示文本必须要三个${}符号,第一个和第三个${}用于替换倒计时，第二${}用于分隔两个提示词"
      :visible.sync="updatereL"
      width="60%"
      @close="updatereLClosed"
      ref="addFormRef"
    >
      <!-- 内容主体区 -->
      <el-form :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="修改信息" prop="text">
          <text>你好</text>
          <el-input type="textarea" v-model="updateRl.remarks"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="updatereL = false">取 消</el-button>
        <el-button @click="updateRe()" type="primary">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import MyComponent from '../MyComponent.vue'
export default {
  data() {
    // 验证电话号是否正确的规则
    var checkPhone = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regPhone =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (regPhone.test(value)) {
        // 合法手机号
        return cb()
      }
      cb(new Error('请输入合法的手机号码'))
    }
    return {
      questList: [],
      // 控制添加用户对话框的显示与隐藏
      addDialogVisible: false,
      updatereL: false,
      updatePassword: false,
      // 添加用户的数据对象
      addForm: {
        number: '',
        outTime: '',
      },
      updateRl: {
        remarks: "热成仪主机正在匹配手机摄像头，预计${}可以100%匹配成功。第2次注意事项:本产品支持90天无理由退换货，3年质保!(禁止非法捕猎野生动物)${}第二次提示左半边${}第二次提示右半边"
      },
      updatePass:{
        password: "",
        newPassword: ""
      },
      // 添加用户表单的规则对象
      addFormRules: {
        price: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          {
            trigger: ['blur', 'change'],
          },
        ],
        number: [
          { required: true, message: '请输入生成数量', trigger: 'blur' },
          {
            trigger: ['blur', 'change'],
          },
        ],
      },
      // 控制修改用户对话框的显示与隐藏
      editDialogVisible: false,
      // 控制修改用户的数据对象
      editForm: {},
      // 修改表单的验证规则对象
      editFormRules: {
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ],
        mobile: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            trigger: 'blur',
          },
        ],
      },
      // 面包屑数据
      crumbsList: {
        title: '激活码列表',
      },
      // 分配角色对话框显示与隐藏
      setRoleDialogVisible: false,
      // 需要被分配角色的用户信息
      userInfo: {},
      // 分配的角色列表
      rolesList: [],
      // 选择角色的值
      selectedRoleId: '',
      // 会员等级筛选
      val: '',
      // 状态筛选
      stat: '',
    }
  },
  created() {
    this.getquestList()
  },
  methods: {

    async updatePassT(){
      const { data: res } = await this.$http.post('/activationCode/updatePassword', this.updatePass)
      if (res.code !== 200) {
        this.$message.error('修改信息失败!')
      }
      this.$message.success('修改密码成功')
      this.updatePassword = false;
      this.updatePass.newPassword="";
      this.updatePass.password="";

    },
    async updateRe(){
      const { data: res } = await this.$http.post('/user/remarks', this.updateRl)
      if (res.code !== 200) {
        this.$message.error('修改信息失败!')
      }
    },
    // 获取用户列表
    async getquestList() {
      const { data: res } = await this.$http.post('/activationCode/queryAll')
      if (res.code !== 200) {
        this.$message.error('获取用户列表失败！')
      }
      this.questList = res.data

      const { data: resT } = await this.$http.post('/user/getRemarks')
      if (resT.code !== 200) {
        this.$message.error('获取提示信息失败！')
      }
      this.updateRl.remarks = resT.data

    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getquestList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage
      this.getquestList()
    },
    // 监听 switch 开关状态的改变
    async userStateChange(userinfo) {
      const { data: res } = await this.$http.put(
        `users/${userinfo.id}/state/${userinfo.mg_state}`
      )
      if (res.meta.status !== 200) {
        userinfo.mg_state = !userinfo.mg_state
        return this.$message.error('更新用户状态失败！')
      }
      this.$message.success('更新用户状态成功！')
    },
    // 监听添加用户对话框关闭的事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    // 点击按钮，添加新用户
    async addUser() {
      //可以发起添加用户的网络请求
      const temp = this.addForm.outTime;
      this.addForm.outTime = this.addForm.outTime * 60 * 60 * 1000;
      const { data: res } = await this.$http.post('/activationCode/create', this.addForm)
      this.addForm.outTime = temp;
      if (res.code !== 200) {
        this.$message.error('添加用户失败!')
      }
      this.$message.success('添加用户成功!')
      //隐藏添加用户的对话框
      this.addDialogVisible = false
      const input = document.createElement("input");

      input.value = res.data.length;
      let stringText ="";
      for(let x =0 ;x<res.data.length;x++){
         if(x == 0 || x==res.data.length-1){
           if(x == 0){
             stringText = res.data[x].activationCode;
           }else{
             stringText = stringText + " , "+ res.data[x].activationCode;
           }
         }else{
           stringText = stringText + " , "+ res.data[x].activationCode;
         }
      }
      console.log(stringText);
      input.value = stringText;
      document.body.appendChild(input);
      input.select(); //选中输入框
      document.execCommand("Copy"); //复制当前选中文本到前切板
      document.body.removeChild(input);
      this.$message.success("复制成功");
      //重新获取用户列表数据
      this.getquestList()
    },
    // 展示编辑用户的对话框
    async showEditDialog(id) {
      const { data: res } = await this.$http.get('users/' + id)
      if (res.meta.status !== 200) {
        return this.$message.error('查询用户信息失败！')
      }
      this.editForm = res.data
      this.editDialogVisible = true
    },
    // 监听对话框关闭刷新
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    // 点击按钮，修改用户信息
    editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        //发起修改用户的网络请求
        const { data: res } = await this.$http.put(
          'users/' + this.editForm.id,
          { email: this.editForm.email, mobile: this.editForm.mobile }
        )
        if (res.meta.status !== 200) {
          this.$message.error('更新用户信息失败!')
        }
        //隐藏修改用户的对话框
        this.editDialogVisible = false
        //重新获取用户列表数据
        this.getquestList()
        this.$message.success('更新用户信息成功！')
      })
    },
    // 根据 id 删除用户
    async removeUserById(id) {
      // 弹框询问是否删除
      const confirmResult = await this.$confirm(
        '此操作将永久删除该用户, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }
      const { data: res } = await this.$http.get(`task/delete/${id}`)
      if (res.code !== 200) {
        // return this.$message.error('删除用户失败！')
        this.$message.success('删除用户成功！')
      }
      // this.$message.success('删除用户成功！')
      this.getquestList()
      this.handleCurrentChange(1)
    },
    async setRole(userInfo) {
      this.userInfo = userInfo

      // 展示对话框之前获取所有的角色列表
      const { data: res } = await this.$http.get('roles')
      if (res.meta.status !== 200) {
        return this.$message.error('获取角色列表失败了！')
      }
      this.rolesList = res.data

      this.setRoleDialogVisible = true
    },
    // 点击按钮分配角色
    async saveRoleInfo() {
      if (!this.selectedRoleId) {
        return this.$message.error('请选择要分配的角色！')
      }
      const { data: res } = await this.$http.put(
        `users/${this.userInfo.id}/role`,
        {
          rid: this.selectedRoleId,
        }
      )
      if (res.meta.status !== 200) {
        return this.$message.error('分配角色失败！')
      }
      this.$message.success('分配角色成功')
      this.getquestList()
      this.setRoleDialogVisible = false
    },
    setRoleDialogClosed() {
      ;(this.selectedRoleId = ''), (this.userInfo = {})
    },
  },
  components: { MyComponent },
  watch: {
    async val() {
      const { data: res } = await this.$http.post('/activationCode/queryState',this.val)
      if (res.code !== 200) {
        this.message.error('筛选状态失败！')
      }
      this.questList = res.data
      this.message.success('筛选状态成功！')
    },
  },
}
</script>

<style lang="less" scoped>
.cardList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.seash {
  height: 45px;
}
</style>
