import Vue from 'vue'
import VueRouter from 'vue-router'
import Login_Interface from '../components/Login_Interface.vue'
import Manage_Home from '../components/Manage_Home.vue'
import Quest_Interface from '../components/quest/Quest_Interface.vue'
import Rights_Interface from '../components/power/Rights_Interface.vue'
import Roles_Interface from '../components/power/Roles_Interface.vue'
import Withdraw_Interface from '../components/power/Withdraw_Interface'
import User_Interface from '../components/user/User_Interface'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login_Interface },
    {
      path: '/home',
      component: Manage_Home,
      redirect: '/quest',
      children: [
        // 子组件
        { path: '/quest', component: Quest_Interface },
        { path: '/rights', component: Rights_Interface },
        { path: '/roles' , component: Roles_Interface},
        { path: '/withdraw' , component: Withdraw_Interface},
        // { path: '/user' , component: User_Interface},
      ]
    }
  ]
})


// 挂载路由守卫
router.beforeEach((to, from, next) => {
  // to 代表将要访问的路径
  // from 代表从那个路径跳进来
  // next 是一个函数，表示放行
  //   next() 放行    next("/login") 强制跳转到路径

  if (to.path === '/login') return next();
  // 获取token
  const tokenStr = window.sessionStorage.getItem('saToken');
  // 判断是否携带token
  if (tokenStr) return next()
  next('/login');
})

export default router
