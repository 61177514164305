<template>
  <div>
    <!-- 卡片视图 -->
    <el-card>
      <div class="box">
        <!-- 面包屑导航区 -->
        <my-component :crumbsLisTtitle="crumbsList.title"></my-component>
        <el-input
          placeholder="请输入内容"
          clearable
          @clear="getUserList"
          class="ipt"
        >
          <el-button
            @click="getUserList"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </div>
      <el-table :data="rightsList" border stripe>
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="性别" prop="gender"></el-table-column>
        <el-table-column label="角色" prop="path"></el-table-column>
        <el-table-column label="账号" prop="account"></el-table-column>
        <el-table-column label="联系方式" prop="phone"></el-table-column>
        <el-table-column label="启用状态" prop="status"></el-table-column>
        <el-table-column label="余额" prop="balance"></el-table-column>
        <el-table-column label="代理等级" prop="agencyRank"></el-table-column>
        <el-table-column label="VIP等级" prop="vipRank"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import MyComponent from '../MyComponent.vue'
export default {
  components: { MyComponent },
  data() {
    return {
      // 权限列表
      rightsList: [],
      // 面包屑列表
      crumbsList: {
        title: '用户管理',
      },
    }
  },
  created() {
    this.getRightsList()
  },
  methods: {
    // 获取权限列表
    async getRightsList() {
      const { data: res } = await this.$http.post('users/queryList')
      if (res.code !== 200) {
        return this.$message.error('获取权限列表失败！')
      }
      this.rightsList = res.data
    },
    getUserList() {},
  },
}
</script>

<style lang="less" scoped>
.ipt {
  width: 350px;
}
.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>