// 公共组件
<template>
  <div>
    <el-form :inline="true">
      <el-form-item :label="crumbsLisTtitle">
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        // 当前的页数
        pagenum: 1,
        pagesize: 2,
      },
    }
  },
  methods: {
    getUserList() {},
  },
  props: ['crumbsLisTtitle'],
}
</script>

<style lang="less" scoped>
.title {
  margin-left: 20px;
  width: 150px;
}
</style>