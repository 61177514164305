<template>
  <div>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 面包屑导航区 -->
      <div class="shenhe">
        <my-component :crumbsLisTtitle="crumbsList.title"></my-component>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="审核筛选">
            <el-select placeholder="未审核" v-model="val">
              <el-option label="未审核" value="0"></el-option>
              <el-option label="已审核" value="1"></el-option>
              <el-option label="审核未通过" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="rightsList" border stripe>
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="用户id" prop="userId"></el-table-column>
        <el-table-column label="开户行" prop="opBank"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="银行卡" prop="bankId"></el-table-column>
        <el-table-column label="申请类型" prop="type"></el-table-column>
        <el-table-column label="金额" prop="money"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="状态" prop="stateString"></el-table-column>
        <el-table-column label="操作" prop="controls">
          <template slot-scope="scope">
            <el-button type="primary" @click="showDialogVisible(scope.row.id, scope.row.userId)"
              v-if="scope.row.state === '0'">审批</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 图片显示区 -->
    <el-dialog title="审核图片" :visible.sync="photoDialogVisible"  @close="photoDialogClosed"
      ref="photoFormRef">  
      <!-- 内容主体区 -->
     <div class="photo"></div>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateQuest(2)" type="danger">拒 绝</el-button>
        <el-button @click="updateQuest(1)" type="primary">同 意</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import MyComponent from '../MyComponent.vue'
  export default {
    components: {
      MyComponent
    },
    data() {
      return {
        // 权限列表
        rightsList: [],
        // 面包屑列表
        crumbsList: {
          title: '提现审批',
        },
        photoDialogVisible: false,
        val: '',
        topList: {
          id: '',
          userId: '',
          state: '',
        },
      }
    },
    created() {
      this.getRightsList()
    },
    methods: {
      // 获取任务列表
      async getRightsList() {
        const {
          data: res
        } = await this.$http.post('order/withdrawList/0')
        if (res.code !== 200) {
          return this.$message.error('获取提现审批列表失败！')
        }
        this.rightsList = res.data
      },
      showDialogVisible(id, userId) {
        this.photoDialogVisible = true
        this.topList.id = id
        this.topList.userId = userId
      },
      // 任务审批操作
      async updateQuest(state) {
        this.topList.state = state
        const {
          data: res
        } = await this.$http.post(
          'order/withdrawReview',
          this.topList
        )
        if (res.code !== 200) {
          return this.$message.error('审批失败！')
        }
        this.$message.success('审批成功！')
        this.photoDialogVisible = false
        this.getRightsList()
      },
    },
    watch: {
      async val() {
        const {
          data: res
        } = await this.$http.post(
          `order/withdrawList/${this.val}`
        )
        if (res.code !== 200) {
          this.message.error('筛选状态失败！')
        }
        this.rightsList = res.data
        this.message.success('获取状态成功！')
      },
    },
  }
</script>

<style lang="less" scoped>
  .shenhe {
    display: flex;
    justify-content: space-between;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
  }
</style>
